export const GetTokenFromUrl = (url: string): string => {
  const queryValueIndex = url.indexOf('=');
  return decodeURIComponent(url.slice(queryValueIndex + 1));
};

export const GetBaseUrlFromFullUrl = (url: string): string => {
  return `${url.slice(0, url.lastIndexOf('/'))}/`;
};

export const GetCandidateIdFromToken = (token: string): string => {
  const jwtDataPart = token.split('.')[1];
  const decoded = atob(jwtDataPart);
  return JSON.parse(decoded.toString()).candidateId;
};
