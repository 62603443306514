import { GetBaseUrlFromFullUrl, GetTokenFromUrl } from './utils/UrlUtils';

const eventTypes = ['DestroyGame'];

interface IAssessmentComponent {
  init(): Promise<void>;
  attachTo(element: HTMLElement): void;
}

/**
 * @example - This example creates a new web component and attaches it to the body
 * const component = new AssessmentComponent('https://personality-web.revelian.com', 'assessment-personality');
 * component.init();
 * component.attachTo(document.body);
 */
export class AssessmentComponent implements IAssessmentComponent {
  private readonly component: HTMLElement;

  constructor(private readonly url: string, private readonly componentName: string) {
    this.component = document.createElement(this.componentName);
  }

  /**
   * Initializes the component by attaching the script, and then creating the element once the script has
   * loaded
   */
  public async init(): Promise<void> {
    await this.initScript();
    this.initElement();
  }

  /**
   * Attaches the component to the DOM
   */
  public attachTo(element: HTMLElement): void {
    console.debug(`Attaching <${this.componentName}> to the DOM`);
    element.appendChild(this.component);
    this.component?.classList.add('assessment');
  }

  public addListeners(): void {
    eventTypes.forEach((eventType) => {
      this.component.addEventListener<any>(eventType, (event: CustomEvent) => {
        if (eventType === 'DestroyGame') {
          const returnUrl = event.detail.returnUrl;
          window.location.assign(returnUrl);
        }
      });
    });
  }

  /**
   * Creates the script element, adds the source of the web component and appends it to the DOM
   */
  private initScript(): Promise<boolean> {
    const script = document.createElement('script');
    const baseUrl = GetBaseUrlFromFullUrl(this.url);
    console.debug(`Creating script, with src ${baseUrl}component.js`);
    const promise = new Promise<boolean>((resolve, reject) => {
      script.onload = () => resolve(true);
      script.onerror = (e) => reject(e);
    });
    document.head.appendChild(script);
    script.src = baseUrl + `component.js`;

    return promise;
  }

  /**
   * Sets the relevant attributes on the component
   */
  private initElement(): void {
    // Attaches the token to the component
    const token = GetTokenFromUrl(this.url);

    // These should be consolidated but adding both for support for now. work required in either the assessments or games
    this.component.setAttribute('token', token);
    this.component.setAttribute('accesstoken', token);
  }
}
