export class ResearchEntry {
  public static fromAPI(entry: any): ResearchEntry {
    return new ResearchEntry(entry['url'], entry['name'], entry['returnUrl'], entry['canEmbed']);
  }

  constructor(
    public url: string,
    public name: string,
    public returnUrl: string,
    public canEmbed = false
  ) {}
}
